import * as React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { Analytics } from "@vercel/analytics/react";

import Header from "../components/Header";
import HeaderHero from "../components/HeaderHero";
import Introduction from "../components/PrivacyContent";
import CareersFeatured from "../components/CareersFeatured";
import CareersAll from "../components/CareersAll";
import FormWrapper from "../components/Form/FormWrapper";
import Apply from "../components/Apply";
import Footer from "../components/Footer";
// import Notifications from 'react-notify-toast'

// Styles

// Markup
const PrivacyPolicy = (data) => {
  // vacatures data
  const CareersData = data.data.allContentfulVacature;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PIC reno-decor vacatures</title>
        <meta name="description" content="PIC reno-decor vacatures" />
      </Helmet>
      {/* <Notifications /> */}
      <Header />
      <HeaderHero
        data={data.data.allContentfulPages}
        title="Privacy Policy & Cookies"
      />
      <Introduction />
      <Footer />
      <Analytics />
    </>
  );
};

export default PrivacyPolicy;

export const query = graphql`
  query {
    allContentfulVacature {
      nodes {
        functieTitel
        vacatureBeeld {
          gatsbyImageData(
            width: 900
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
          title
        }
        functieOmschrijving {
          functieOmschrijving
        }
        id
        vacaturePdf {
          file {
            url
          }
        }
        projectleiderOfArbeider
        interessanteVacature
      }
    }
    allContentfulPages(filter: { titel: { eq: "Vacature pagina" } }) {
      nodes {
        titel
        titelOpBeeld
        headerBeeld {
          gatsbyImageData(
            width: 900
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
          title
        }
        introductie {
          introductie
        }
        solliciterenStap1
        solliciterenStap2
        solliciterenStap3
        solliciterenStap4
        solliciterenStap5
      }
    }
  }
`;
