import * as React from "react"
import styled from 'styled-components'
import { fontsizes,media } from "../lib/style-utils"

import Cta from "./buttons/CTA"
import Blocks from "./Icons/Blocks"

// Styles
const IntroWrapper = styled.div``

const IntroInner = styled.p`
    max-width: 85%;
    font-size: ${fontsizes.fs_text_normal_mob};
    line-height: ${fontsizes.lh_text_normal_mob};
    ${media.tablet`
        max-width: 960px;
        font-size: ${fontsizes.fs_text_normal};
        line-height: ${fontsizes.lh_text_normal};
    `}

    p {
        margin-bottom: 2rem;
    }
`

const CtaWrapper = styled.div`
    z-index: 800;
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    ${media.lgDesktop`
        position: absolute;
        bottom: auto;
        top: 0;
        right: 4%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;    
    `}
`
const TextTitle = styled.h2`
    display:flex;
    font-weight: bold;
    margin-bottom: 1.8rem;
    font-size: 2rem;
`

// Markup
const Introduction = (data) => {
  
    // all vacatures
    const IntroData = data.data;
    
    return (
        <IntroWrapper className="relative">
            <div className="container">
                <div className="px-4 py-8 md:py-16">
                    <TextTitle>Privacybeleid</TextTitle>
                    <IntroInner>
                        <p>De bescherming van uw privacy is zeer belangrijk voor PIC. Hoewel de meeste informatie op deze site toegankelijk is zonder dat er persoonlijke gegevens moeten worden verstrekt, is het mogelijk dat de gebruiker om persoonlijke informatie gevraagd wordt. Deze informatie zal uitsluitend worden gebruikt voor klantenbeheer. De gebruiker kan zich, kosteloos en op verzoek, verzetten tegen het gebruik van zijn gegevens voor direct marketing. Hiertoe dient u uw verzet te richten aan info@pic-renodecor.be. Uw persoonsgegevens worden nooit doorgegeven aan derden.</p>
    
                        <p>Conform de wet verwerking persoonsgegevens van 08/12/1992 beschikt de gebruiker over een wettelijk recht op inzage en correctie van zijn persoonsgegevens. Mits bevestiging van uw identiteit (kopie identiteitskaart), kunt u via een schriftelijke, gedateerde en ondertekende aanvraag aan info@pic-renodecor.be, gratis de schriftelijke mededeling bekomen van uw persoonsgegevens. U kunt ook verzoeken om correctie van gegevens die onjuist, onvolledig of irrelevant zouden zijn.</p>
    
                        <p>PIC kan anonieme of geaggregeerde gegevens verzamelen van niet-persoonlijke aard, zoals browser type of IP-adres, het besturingsprogramma dat u gebruikt of de domeinnaam van de website langs waar u naar onze-website gekomen bent, of waarlangs u die verlaat. Dit maakt het ons mogelijk om onze website permanent te optimaliseren voor de gebruikers.</p>
                    </IntroInner>
                    <TextTitle>Cookies</TextTitle>
                    <IntroInner>
                        <p>Cookies zijn kleine data pakketten die door een bezochte website op uw computer worden geplaatst. De volgende keer dat u de website bezoekt, zorgen cookies ervoor dat uw apparaat wordt herkend. Websites gebruiken cookies om tijdelijke informatie over bezoeken en bezoekers op te slaan. Voor elk bezoek kunnen verschillende soorten cookies worden gebruikt.</p>
                    </IntroInner>
                </div>
            </div>
        </IntroWrapper>
    )
}

export default Introduction